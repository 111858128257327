import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import React, { FunctionComponent } from "react";
import IconArrow from "./icon_arrow.svg";
import { Urls } from "@/utils/Urls";

type ServiceType = "couriers" | "medical" | "in-clinic";

type SerivcesItemProps = {
  type: ServiceType;
};

const getVideoSrc = (type: ServiceType) => {
  switch (type) {
    case "couriers":
      return "/box";
    case "in-clinic":
      return "/heart";
    case "medical":
      return "/crown";
  }
};

const getTitle = (type: ServiceType) => {
  switch (type) {
    case "couriers":
      return "Medical Couriers";
    case "in-clinic":
      return "In-clinic Services";
    case "medical":
      return "Medical Services";
  }
};

const getDescription = (type: ServiceType) => {
  switch (type) {
    case "couriers":
      return "Our GMP-trained couriers deliver medical-only parcels for a secure and damage-free service";
    case "in-clinic":
      return "A range of in-clinic services,  health care checks and more in our laboratory in Central London ";
    case "medical":
      return "Our team of medical specialists will come to wherever you are to take samples or perform tests";
  }
};

const getUrl = (type: ServiceType) => {
  switch (type) {
    case "couriers":
      return Urls.COURIERS;
    case "in-clinic":
      return Urls.IN_CLINIC;
    case "medical":
      return Urls.MEDICAL_SERVICES;
  }
};

const ServicesItem: FunctionComponent<SerivcesItemProps> = ({ type }) => {
  return (
    <Link
      target={type === "in-clinic" ? "_blank" : undefined}
      href={getUrl(type)}
      className={clsx(
        "group w-full flex p-px rounded-lg outline-none outline-0 h-full relative overflow-hidden",
        "bg-lines-on-dark",
        "focus-visible:bg-gradient-to-tr from-mint-500 to-red-500 from-25% to-60%",
        "md:h-fit",
        "lg:h-full"
      )}
    >
      <div
        className={clsx(
          "absolute top-1/2 left-0 right-0 mx-auto w-20 origin-top h-[200%] z-0 animate-spin-slow",
          "from-transparent to-95% bg-gradient-to-l",
          "group-focus-visible:opacity-0",
          type === "in-clinic" && "to-light-second",
          type === "medical" && "animation-delay-400 to-mint-500",
          type === "couriers" && "animation-delay-200 to-red-500"
        )}
      />
      <div
        className={clsx(
          "w-full h-full rounded-lg bg-dark-main flex flex-col z-[1]",
          "py-6 px-5",
          "py-8 px-5", //sm
          "lg:px-4",
          "xl:pt-12 xl:pb-8 xl:px-8",
          "group-hover:bg-dark-second"
        )}
      >
        <div className="mb-6 lg:mb-8 xl:mb-8">
          <video
            tabIndex={-1}
            className={clsx(
              "object-cover h-[6.5rem] xl:h-[7.5rem] pointer-events-none",
              //visual adjustments because of different size:
              type === "in-clinic" && "scale-110 -translate-y-2 -translate-x-2"
            )}
            autoPlay
            playsInline
            muted
            loop
          >
            <source
              src={`video/${getVideoSrc(type)}.mov`}
              type="video/quicktime"
            />
            <source src={`video/${getVideoSrc(type)}.webm`} type="video/webm" />
            <track kind="captions" />
          </video>
        </div>
        <h2 className={clsx("title-s-bold", "xl:title-m-bold")}>
          {getTitle(type)}
        </h2>
        <p
          className={clsx(
            "body-s text-light-second mt-1",
            "xl:mt-2",
            "2xl:body-m"
          )}
        >
          {getDescription(type)}
        </p>
        <span className="flex gap-2 items-end mt-6 lg:mt-8 xl:mt-10 flex-1">
          Find out more
          <Image
            alt=""
            src={IconArrow}
            width={12}
            height={12}
            className="mb-1"
          />
        </span>
      </div>
    </Link>
  );
};

export default ServicesItem;
