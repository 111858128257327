import { Navbar, Footer } from "@purpledeerstudio/logistics-components";
import clsx from "clsx";
import HeroSection from "@/sections/HeroSection";
import React, { useRef, useState } from "react";
import Lines from "@/components/Lines";
import ServicesSection from "@/sections/ServicesSection";
import ServicesDescriptionSection from "@/sections/ServicesDescriptionSection";
import DronesCarsSection from "@/sections/DronesCarsSection";
import OpinionsSection from "@/sections/OpinionsSection";
import { useInView } from "framer-motion";
import FontStyle from "@/styles/FontStyle";
import GeneralHead from "@/components/GeneralHead";
import { FooterUrls } from "@/utils/Urls";
import CookiesBox from "@/components/CookiesBox";
import ContactSection from "@/sections/ContactSection";
import VerticalLines from "@/components/Decorators/VerticalLines";
import PageBackground from "@/components/Decorators/PageBackground";
import NhsSection from "@/sections/NhsSection";
import SkipToContent from "@/components/SkipToContent";
import Main from "@/components/Main";

export default function Home() {
  const [skipToContent, setSkipToContent] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref);

  return (
    <>
      <FontStyle />
      <GeneralHead />
      <SkipToContent href="#main" />
      <CookiesBox />

      <Navbar variant="dark" selection="home" urls={FooterUrls} />
      <Main
        onClick={() => setSkipToContent(false)}
        className={clsx(
          "text-light-main",
          "flex flex-col justify-between min-h-screen"
        )}
      >
        <div ref={ref}>
          <HeroSection />
        </div>

        <div className="relative">
          <PageBackground />
          <VerticalLines className="top-96 bottom-32" />

          <div className="-mt-screen flex flex-col">
            <ServicesSection />

            <ServicesDescriptionSection />

            <DronesCarsSection />

            <OpinionsSection />

            <NhsSection />

            <ContactSection />
          </div>
        </div>
      </Main>

      <Footer variant="dark" urls={FooterUrls} />

      <Lines />
    </>
  );
}
