import MEDIILogo from "./logos/medii.webp";
import MedicalLaboratoriesLogo from "./logos/medical-laboratories.webp";
import CPSLogo from "./logos/cps.webp";
import LDPATHLogo from "./logos/ldpath.webp";
import BIONIQLogo from "./logos/bioniq.webp";
import THRIVALogo from "./logos/thriva.webp";
import KCLLogo from "./logos/kcl.webp";
import LondonMedicalConciergeLogo from "./logos/london-medical-concierge.webp";
import InHealthLogo from "./logos/in-health.webp";
import EurofinsLogo from "./logos/eurofins.webp";
import GenovaLogo from "./logos/genova.webp";
import DoctorsLaboratoryLogo from "./logos/doctors-laboratory.webp";
import TuliLogo from "./logos/tuli.webp";
import NumanLogo from "./logos/numan.webp";
import { StaticImageData } from "next/image";

export type Partner = {
  name: string;
  logo: StaticImageData;
};

export const PARTNERS: Partner[] = [
  { name: "MEDii", logo: MEDIILogo },
  { name: "Medical Laboratories", logo: MedicalLaboratoriesLogo },
  { name: "Cellular Pathology Server", logo: CPSLogo },
  { name: "LDPATH", logo: LDPATHLogo },
  { name: "BIONIQ", logo: BIONIQLogo },
  { name: "Numan", logo: NumanLogo },
  { name: "TULI", logo: TuliLogo },
  { name: "THRIVA", logo: THRIVALogo },
  { name: "King's College London", logo: KCLLogo },
  { name: "london-medical-concierge", logo: LondonMedicalConciergeLogo },
  { name: "In Health", logo: InHealthLogo },
  { name: "Eurofins", logo: EurofinsLogo },
  { name: "Genova", logo: GenovaLogo },
  { name: "The Doctors Laboratory", logo: DoctorsLaboratoryLogo },
];
