import clsx from "clsx";
import React, { ButtonHTMLAttributes, FunctionComponent } from "react";
import IconPause from "./icon_pause.svg";
import IconPlay from "./icon_play.svg";
import Image from "next/image";

type PlayPauseButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "children"
> & {
  isPlaying: boolean;
};

const PlayPauseButton: FunctionComponent<PlayPauseButtonProps> = ({
  className,
  isPlaying,
  ...props
}) => (
  <button
    aria-label="Play or pause video"
    className={clsx(
      className,
      "transition-all rounded-full focus-circle relative",
      "w-10 h-10 aspect-square",
      "sm:w-8 sm:h-8",
      "xl:w-10 xl:h-10",
      "hover-default"
    )}
    {...props}
  >
    <Image
      src={IconPlay}
      alt="Play video icon"
      className={clsx(
        isPlaying ? "opacity-0" : "opacity-100",
        "absolute left-0 right-0 top-0 bottom-0"
      )}
    />
    <Image
      src={IconPause}
      alt="Pause video icon"
      className={clsx(
        isPlaying ? "opacity-100" : "opacity-0",
        "absolute left-0 right-0 top-0 bottom-0"
      )}
    />
  </button>
);

export default PlayPauseButton;
