import Grid from "@/components/Grid";
import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import React, {
  ButtonHTMLAttributes,
  FunctionComponent,
  useState,
} from "react";
import { SelectedService } from "./SelectedService";
import Card from "./Card";

type ServiceButtonProps = {
  selected: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ServiceButton: FunctionComponent<ServiceButtonProps> = ({
  selected,
  className,
  children,
  ...props
}) => (
  <button
    className={clsx(
      className,
      "flex-1 h-[3.75rem] relative outline-none group",
      "md:h-16",
      "xl:h-[4.25rem]",
      "bg-gradient-to-r from-mint-500 from-25% via-mint-500 via-35% to-red-500 to-60%",
      "focus-visible:bg-gradient-to-br",
      selected ? "text-light-main" : "text-light-second",
      selected
        ? "body-m-bold md:title-s-bold xl:title-m-bold"
        : "body-m md:title-s xl:title-m"
    )}
    {...props}
  >
    <div
      className={clsx(
        "absolute left-0 top-0 right-0 flex items-center justify-center",
        selected ? "pt-px bottom-px bg-dark-second" : "bottom-0 bg-dark-main",
        "group-focus-visible:bottom-px group-focus-visible:top-px group-focus-visible:left-px group-focus-visible:right-px group-focus-visible:p-0"
      )}
    >
      <div className="group-hover:scale-105 transition-transform duration-300">
        {children}
      </div>
    </div>
  </button>
);

const DronesCarsSection = () => {
  const [currentSelection, setCurrentSelection] =
    useState<SelectedService>("drones");

  return (
    <section className="flex flex-col my-10 md:my-[4.5rem] 2xl:my-20">
      <Grid>
        <div
          className={clsx(
            "col-span-full flex flex-col gap-4 text-center",
            "md:col-start-2 md:col-end-8",
            "lg:col-start-2 lg:col-end-12"
          )}
        >
          <h2
            className={clsx(
              "heading-4 font-bsc",
              "sm:heading-3",
              "xl:heading-2"
            )}
          >
            We believe that the future
            <br className="hidden lg:inline-block" /> lies in innovation
          </h2>
          <p className={clsx("body-s text-light-second", "lg:body-m")}>
            That&apos;s why we&apos;re committed to transforming the industry
            through the latest cutting-
            <br className="hidden xl:inline-block" />
            edge technology and unparalleled customer service
          </p>
        </div>
      </Grid>
      <Grid>
        <div
          className={clsx(
            "col-span-full flex flex-col mt-16",
            "md:mt-24",
            "xl:col-end-12 xl:col-start-2 xl:mt-32"
          )}
        >
          <div className="flex border-b border-b-lines-on-dark">
            <ServiceButton
              selected={currentSelection === "drones"}
              onClick={() => setCurrentSelection("drones")}
            >
              <span className="sm:hidden">DRONES</span>
              <span className="hidden sm:inline-block">MEDICAL DRONES</span>
            </ServiceButton>

            <ServiceButton
              selected={currentSelection === "cars"}
              onClick={() => setCurrentSelection("cars")}
            >
              <span className="sm:hidden">ECO CARS</span>
              <span className="hidden sm:inline-block">ELECTRIC CARS</span>
            </ServiceButton>
          </div>
          <Card key="drones" service={currentSelection} />
        </div>
      </Grid>
    </section>
  );
};

export default DronesCarsSection;
