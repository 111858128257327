import { HTMLMotionProps, motion } from "framer-motion";
import React, { FunctionComponent } from "react";

type TextLineAnimationProps = Omit<
  HTMLMotionProps<"span">,
  "initial" | "exit" | "animate" | "transition"
> & {
  delay?: number;
};

const TextLineAnimation: FunctionComponent<TextLineAnimationProps> = ({
  delay,
  ...props
}) => (
  <motion.span
    initial={{ opacity: 0, translateX: -100 }}
    animate={{ opacity: 1, translateX: 0 }}
    transition={{ delay: delay, bounce: false, duration: 0.5 }}
    className="translate-x-4 inline-block"
    {...props}
  />
);

export default TextLineAnimation;
