import { CqcBadge, IsoBadge, UkasBadge } from "@/components/Badges/PageBadges";
import Grid from "@/components/Grid";
import LinesContainer from "@/components/LinesContainer";
import PlayPauseButton from "@/components/PlayPauseButton";
import RoundedLink from "@/components/RoundedLink";
import { NavbarSpace } from "@purpledeerstudio/logistics-components";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { getSelectorsByUserAgent } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { Urls } from "@/utils/Urls";
import { ariaNewTab } from "@/utils/ariaNewTab";
import TextLineAnimation from "@/components/TextLineAnimation";
import { Target, TargetAndTransition, motion } from "framer-motion";

type VideoSource =
  | "video/video_bg_horizontal.mp4"
  | "video/video_bg_vertical.mp4";

const HeroElementsAnimation: {
  initial: Target;
  animate: TargetAndTransition;
} = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { delay: 1, duration: 0.9 },
  },
};

const HeroSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPoster, setShowPoster] = useState<boolean>(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [source, setSource] = useState<"horizontal" | "vertical">();

  const playPauseVideo = () => {
    setShowPoster(false);
    setVideoLoaded(true);
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlaying((p) => !p);
  };

  useEffect(() => {
    if (!videoRef.current || !source) return;
    const src: VideoSource =
      source === "horizontal"
        ? "video/video_bg_horizontal.mp4"
        : "video/video_bg_vertical.mp4";
    if (!videoRef.current.src.includes(src)) videoRef.current.src = src;
  }, [source]);

  useEffect(() => {
    const video = videoRef.current;

    const resizeObserver = new ResizeObserver((entries) => {
      // We only have one entry, so we can use entries[0].
      const { height, width } = entries[0].contentRect;
      const isHorizontal = width > height / 1.25;
      setSource(isHorizontal ? "horizontal" : "vertical");
    });

    resizeObserver.observe(video!);

    return () => {
      // Cleanup the observer when the component is unmounted
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    const loadedDataCallback = () => {
      setVideoLoaded(videoRef.current?.readyState === 4);
    };
    const playCallback = () => {
      setIsPlaying(true);
      setVideoStarted(true);
    };

    videoRef.current?.addEventListener("loadeddata", loadedDataCallback);
    videoRef.current?.addEventListener("play", playCallback);
    videoRef.current?.load();

    return () => {
      video?.removeEventListener("loadeddata", loadedDataCallback);
      video?.removeEventListener("play", playCallback);
    };
  }, []);

  useEffect(() => {
    const { isIOS } = getSelectorsByUserAgent(navigator.userAgent);
    let timeout: NodeJS.Timer;
    if (!videoLoaded && isIOS) {
      timeout = setTimeout(() => {
        if (videoRef.current?.currentTime === 0) setShowPoster(true);
      }, 1000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [videoLoaded]);

  return (
    <section
      id="main"
      className={clsx(
        "min-h-screen supports-svh:min-h-screen-svh flex flex-col",
        "tall:h-tall tall:min-h-full"
      )}
    >
      <NavbarSpace />

      <div className={clsx("flex relative flex-col flex-1")}>
        <div
          className={clsx(
            "absolute top-0 left-0 right-0 bottom-0 w-full h-full"
          )}
        >
          <LinesContainer className={clsx("h-full")}>
            <div className="w-full h-full relative">
              {showPoster ? (
                <Image
                  priority={true}
                  src={"/video/bg_vertical_poster.webp"}
                  className="w-full h-full object-cover"
                  width={source === "vertical" ? 560 : 1920}
                  height={source === "vertical" ? 820 : 934}
                  alt=""
                />
              ) : (
                <video
                  tabIndex={-1}
                  loop
                  autoPlay
                  playsInline
                  ref={videoRef}
                  width={source === "vertical" ? 560 : 1920}
                  height={source === "vertical" ? 820 : 934}
                  muted
                  className={clsx(
                    "w-full h-full object-cover block object-top"
                  )}
                >
                  <source src="./video/video_bg_horizontal.mp4" />
                  <track kind="captions" />
                </video>
              )}
              <div
                className={clsx(
                  "absolute top-0 left-0 right-0 bottom-0",
                  "bg-shadow-gradient bg-[length:100%_125px] bg-no-repeat bg-bottom",
                  "lg:bg-[length:100%_160px]"
                )}
              />
            </div>
          </LinesContainer>
        </div>
        <div className="flex-1 flex">
          <Grid className={clsx("z-0 flex-1")}>
            <div className="col-span-full flex flex-col min-h-fit">
              <div
                className={clsx(
                  "mt-14 flex-1 relative",
                  "sm:mt-16",
                  "xl:mt-20"
                )}
              >
                <h1
                  className={clsx(
                    "heading-4 font-bsc max-w-xs",
                    "sm:heading-3 sm:max-w-none",
                    "xl:heading-2",
                    "2xl:heading-1"
                  )}
                >
                  <TextLineAnimation>Dedicated</TextLineAnimation>{" "}
                  <br className="hidden sm:inline-block" />
                  <TextLineAnimation delay={0.2}>
                    medical services
                  </TextLineAnimation>{" "}
                  <br className="hidden sm:inline-block" />
                  <TextLineAnimation delay={0.4}>& couriers</TextLineAnimation>
                </h1>
                <h2
                  className={clsx(
                    "mt-2 font-bsc text-[2rem] leading-9 font-light",
                    "sm:text-4xl",
                    "xl:text-[2.5rem] xl:leading-[2.9375rem]",
                    "2xl:text-5xl 2xl:leading-[3rem]"
                  )}
                >
                  <TextLineAnimation delay={0.6}>
                    wherever you are
                  </TextLineAnimation>
                </h2>
                <motion.div
                  {...HeroElementsAnimation}
                  className={clsx(
                    "flex justify-between mt-10 flex-wrap gap-4",
                    "xl:mt-12"
                  )}
                >
                  <RoundedLink href="#contact">Contact us</RoundedLink>
                  {(videoLoaded || showPoster) && (
                    <PlayPauseButton
                      isPlaying={isPlaying}
                      className={clsx("sm:hidden")}
                      onClick={() => playPauseVideo()}
                    />
                  )}
                </motion.div>
              </div>

              <motion.div
                {...HeroElementsAnimation}
                className={clsx(
                  "flex justify-between col-span-full mt-14 mb-3 items-end flex-1",
                  "sm:mb-4",
                  "lg:mb-6"
                )}
              >
                <div className={clsx("flex gap-4 flex-wrap", "sm:gap-6")}>
                  <Link
                    aria-label={ariaNewTab("UKAS logo")}
                    href={Urls.UKAS}
                    target="_blank"
                    className="focus-default hover-default"
                  >
                    <UkasBadge className="h-12 w-auto xl:h-14 2xl:h-16" />
                  </Link>
                  <Link
                    aria-label={ariaNewTab("CQC logo")}
                    href={Urls.CQC}
                    target="_blank"
                    className="focus-default hover-default hidden sm:block"
                  >
                    <CqcBadge className="h-12 w-auto xl:h-14 2xl:h-16" />
                  </Link>
                  <Link
                    aria-label={ariaNewTab("ISO logo")}
                    href={Urls.ISO}
                    target="_blank"
                    className="focus-default hover-default"
                  >
                    <IsoBadge className="w-12  h-12 xl:h-14 xl:w-14 2xl:h-16 2xl:w-16" />
                  </Link>
                  <Link
                    aria-label={ariaNewTab("CQC logo")}
                    href={Urls.CQC}
                    target="_blank"
                    className="focus-default hover-default block sm:hidden"
                  >
                    <CqcBadge className="h-12 w-auto xl:h-14 2xl:h-16" />
                  </Link>
                </div>
                {(videoLoaded || showPoster) && (
                  <PlayPauseButton
                    isPlaying={isPlaying}
                    className={clsx("hidden sm:block h-fit")}
                    onClick={() => playPauseVideo()}
                  />
                )}
              </motion.div>
            </div>
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
