import clsx from "clsx";
import Image from "next/image";
import React, { FunctionComponent } from "react";
import UkasLogo from "./ukas_logo.webp";
import CqcLogo from "./cqc_logo.webp";
import IsoLogo from "./iso_logo.webp";
import CqcLogoVertical from "./cqc_logo_vertical.webp";

const BadgeContainer: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => (
  <div
    className={clsx(
      "text-light-second flex gap-3 items-center body-extra-small xl:body-small 2xl:body-xs"
    )}
  >
    {children}
  </div>
);

type BadgeProps = {
  className?: string;
};

export const UkasBadge: FunctionComponent<BadgeProps> = ({ className }) => {
  return (
    <BadgeContainer>
      <Image src={UkasLogo} alt="UKAS logo" className={className} />

      <span>
        UKAS
        <br />
        accredited
        <br />
        22387
      </span>
    </BadgeContainer>
  );
};

export const CqcBadge: FunctionComponent<BadgeProps> = ({ className }) => {
  return (
    <BadgeContainer>
      <Image
        src={CqcLogoVertical}
        alt="CQC logo"
        className={clsx(className, "sm:hidden")}
      />

      <Image
        src={CqcLogo}
        alt="CQC logo"
        className={clsx(className, "hidden sm:block")}
      />

      <span className="hidden sm:inline-block">
        CQC registered for
        <br />
        Diagnostic and
        <br />
        Screening Services
      </span>
    </BadgeContainer>
  );
};

export const IsoBadge: FunctionComponent<BadgeProps> = ({ className }) => {
  return (
    <BadgeContainer>
      <Image src={IsoLogo} alt="ISO logo" className={className} />
    </BadgeContainer>
  );
};
