import Grid from "@/components/Grid";
import LinesContainer from "@/components/LinesContainer";
import PlayPauseButton from "@/components/PlayPauseButton";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import React, { useRef, useState, MouseEvent, useEffect } from "react";
import CirclesImage from "./circles.webp";

const HEADINGS = [
  "Medical Services",
  "Medical Couriers",
  "Mobile Phlebotomy",
  "Healthcare Solutions",
];

const ServicesDescriptionSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentHeading, setCurrentHeading] = useState(0);

  useEffect(() => {
    const callback = async () => {
      setCurrentHeading((prev) => {
        if (prev < HEADINGS.length - 1) return prev + 1;
        else return 0;
      });
    };
    const intervalId = setInterval(callback, 4000);
    return () => clearInterval(intervalId);
  }, []);

  const playPauseVideo = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (isPlaying) videoRef.current?.pause();
    else videoRef.current?.play();

    setIsPlaying((p) => !p);
  };

  return (
    <section
      className={clsx("my-10", "md:mt-10", "lg:my-[4.5rem]", "2xl:my-20")}
    >
      <Grid>
        <h2
          className={clsx(
            "col-span-full heading-4 font-bsc max-w-xs md:max-w-none",
            "sm:heading-3",
            "xl:heading-2",
            "2xl:heading-1"
          )}
        >
          <span className="overflow-hidden block">
            <AnimatePresence mode="wait">
              <motion.span
                className="inline-block"
                key={`heading-${currentHeading}`}
                animate={{ translateY: "0%", opacity: 1 }}
                initial={{ translateY: "100%", opacity: 0 }}
                exit={{ translateY: "-100%", opacity: 0 }}
                transition={{ bounce: false, duration: 0.55 }}
              >
                {HEADINGS[currentHeading]}
              </motion.span>
            </AnimatePresence>
          </span>
          Tailored to Your Needs
        </h2>
        <p
          className={clsx(
            "col-span-full mt-3 mb-6 body-s text-light-second",
            "sm:col-span-5 sm:mt-4",
            "md:col-span-6 md:mb-8",
            "lg:col-span-9 lg:mb-14",
            "xl:col-span-8 xl:body-m xl:mb-16",
            "2xl:mb-20"
          )}
        >
          We&apos;re here to ensure that patients receive the care they need,
          when they need it, no matter where they are. We&apos;re passionate
          about providing high-quality medical services that are reliable,
          efficient, and patient-centered.
        </p>
      </Grid>
      <div className="relative z-0">
        <LinesContainer className="absolute top-0 left-0 right-0 bottom-0 -z-[1] hidden items-center lg:flex">
          <div className="h-[17.5rem] border-y border-y-lines-on-dark w-1/2 xl:h-[20.5rem] 2xl:h-[25.75rem]" />
        </LinesContainer>
        <Grid className="z-10">
          <div
            className={clsx(
              "col-span-full row-start-1 flex flex-col  justify-center",
              "sm:col-start-2 sm:col-span-5",
              "md:col-start-4",
              "lg:col-start-1 lg:col-span-4"
            )}
          >
            <div
              className={clsx(
                "flex items-center",
                "lg:mt-11 lg:mb-11 lg:flex-col lg:items-start"
              )}
            >
              <div className="relative z-[1]">
                <motion.div
                  className="rounded-full"
                  initial={{ scale: 0 }}
                  exit={{ scale: 1 }}
                  whileInView={{ scale: 1 }}
                  transition={{ duration: 0.5, easings: ["easeInOut"] }}
                  viewport={{ once: true }}
                >
                  <Image
                    alt="+50 people"
                    src={CirclesImage}
                    className={clsx(
                      "h-[8.5rem] w-[8.5rem] animate-spin-slow",
                      "sm:h-36 sm:w-36",
                      "xl:h-44 xl:w-44",
                      "2xl:h-56 2xl:w-56"
                    )}
                  />
                  <div className="absolute top-0 left-0 right-0 bottom-0 text-center h-fit m-auto flex flex-col">
                    <span className="heading-6 xl:heading-5 2xl:heading-3">
                      +50
                    </span>
                    <span className="body-xs text-light-second sm:body-s xl:body-m 2xl:title-m">
                      people
                    </span>
                  </div>
                </motion.div>
                <div className="bg-dark-main absolute left-0 top-0 right-0 bottom-0 rounded-full -z-[1]" />
              </div>
              <p
                className={clsx(
                  "-ml-9 pl-14 py-3 pr-6 z-0 h-fit text-light-second flex-1",
                  "border-lines-on-dark body-xs bg-dark-second border rounded-r-sm",
                  "sm:body-s",
                  "lg:bg-transparent lg:border-0 lg:ml-0 lg:p-0 lg:mt-4",
                  "xl:body-m"
                )}
              >
                Highly trained couriers and{" "}
                <br className="hidden lg:inline-block" />
                expert medics on board
              </p>
            </div>
          </div>
          <div
            className={clsx(
              "col-span-full row-start-2 mt-6 relative group aspect-[4/3]",
              "bg-gradient-to-tr from-mint-500 via-red-500 from-50% via-60% to-red-500",
              "md:mt-8",
              "lg:row-start-1 lg:col-span-8 lg:mt-0",
              "sm:aspect-video",
              "p-px cursor-pointer rounded-[0.25rem]"
            )}
            onClick={playPauseVideo}
          >
            <video
              poster="/video/video_services_poster.webp"
              className="w-full h-full object-cover pointer-events-none bg-dark-main rounded-[0.25rem]"
              ref={videoRef}
              playsInline
              loop
              muted
            >
              <source src="/video/video_services.mp4" />
              <track kind="captions" />
            </video>

            <PlayPauseButton
              isPlaying={isPlaying}
              onClick={playPauseVideo}
              className={clsx(
                "right-4 bottom-4",
                "sm:left-0 sm:right-0 sm:top-0 sm:bottom-0",
                "w-10 h-10 !absolute m-auto drop-shadow-2xl",
                isPlaying ? "opacity-0" : "opacity-100",
                "group-hover:opacity-100",
                "focus-visible:opacity-100"
              )}
            />
          </div>
        </Grid>
      </div>
    </section>
  );
};

export default ServicesDescriptionSection;
