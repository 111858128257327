import Grid from "@/components/Grid";
import clsx from "clsx";
import React, {
  ButtonHTMLAttributes,
  FunctionComponent,
  useState,
} from "react";
import { PARTNERS } from "./Partners";
import Image from "next/image";
import TrustpilotImage from "./image_trustpilot.svg";
import { OPINIONS } from "./Opitions";
import { AnimatePresence, motion } from "framer-motion";
import ArrowLeft from "./arrow_left.svg";
import Link from "next/link";
import { Urls } from "@/utils/Urls";
import { ariaNewTab } from "@/utils/ariaNewTab";

type PrevNextButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "children"
> & {
  direction: "left" | "right";
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
      scale: 0,
    };
  },
  center: {
    scale: 1,
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      scale: 0,
      zIndex: 0,
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    };
  },
};

const PrevNextButton: FunctionComponent<PrevNextButtonProps> = ({
  direction,
  ...props
}) => (
  <button
    className={clsx(
      "h-10 w-10 rounded-full border-[1.5px] border-lines-on-dark group focus-circle",
      "transition-all hover:scale-110",
      "lg:h-10 lg:w-10",
      "xl:w-12 xl:h-12",
      "hover:bg-dark-second"
    )}
    {...props}
  >
    <Image
      src={ArrowLeft}
      alt="Arrow left"
      aria-label="Previous opinion"
      className={clsx(
        "mx-auto transition-transform h-[0.4063rem]",
        "lg:h-[0.5313rem]",
        "xl:h-[0.625rem]",
        direction === "right" && "rotate-180"
      )}
    />
  </button>
);

const OpinionContent: FunctionComponent<{
  opinion: { name: string; description: string; text: string };
}> = ({ opinion }) => (
  <>
    <h3
      className={clsx(
        "text-center body-m-bold mt-4",
        "lg:title-s-bold lg:mt-10",
        "xl:title-m-bold"
      )}
    >
      <span className="-ml-[1ch]">~ {opinion.name}</span> <br />
      {opinion.description}
    </h3>
    <div className="flex-1 flex justify-center">
      <p
        className={clsx(
          "body-xs text-center mt-2 mb-4   text-light-second",
          "xl:body-m",
          "before:content-['“'] after:content-['”']"
        )}
      >
        {opinion.text}
      </p>
    </div>
  </>
);

const OpinionsSection = () => {
  const [[opinionIndex, direction], setOpinionIndex] = useState([0, 0]);

  const prevOpinion = () => {
    if (opinionIndex === 0) setOpinionIndex([OPINIONS.length - 1, -1]);
    else setOpinionIndex(([i]) => [i - 1, -1]);
  };

  const nextOpinion = () => {
    if (opinionIndex === OPINIONS.length - 1) setOpinionIndex([0, 1]);
    else setOpinionIndex(([i]) => [i + 1, 1]);
  };

  return (
    <section
      className={clsx(
        "flex flex-col my-10 gap-6",
        "md:gap-14",
        "lg:my-[4.5rem] lg:gap-16",
        "2xl:my-20"
      )}
    >
      <Grid>
        <h2
          className={clsx(
            "font-bsc text-light-main heading-4 col-span-full break-words",
            "sm:heading-3 sm:max-w-lg",
            "xl:heading-2 xl:max-w-xl",
            "2xl:heading-1 2xl:max-w-2xl"
          )}
        >
          We build lasting relationships with our partners
        </h2>
      </Grid>
      <Grid>
        <div
          className={clsx(
            "col-span-full",
            "md:col-start-1 md:col-end-5",
            "lg:col-end-7"
          )}
        >
          <div
            className={clsx(
              "col-span-full grid grid-cols-2 gap-x-4 gap-y-3",
              "md:col-start-5 md:col-end-9",
              "lg:col-start-7 lg:col-end-13 lg:gap-y-4",
              "xl:gap-y-4 xl:gap-x-4"
            )}
          >
            {PARTNERS.map((partner) => (
              <div
                key={partner.name}
                className="col-span-1 py-3 flex items-center justify-center bg-dark-second rounded-sm lg:py-4 xl:py-[0.875rem]"
              >
                <Image
                  src={partner.logo}
                  alt={`${partner.name} logo`}
                  className="h-[1.6875rem] w-auto xl:h-9 2xl:h-9"
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={clsx(
            "col-span-full mt-6 h-auto flex flex-col items-center p-6 bg-dark-second rounded-sm",
            "sm:px-8 sm:py-4",
            "md:col-start-5 md:col-end-9 md:mt-0 md:py-0 md:px-2 md:bg-transparent",
            "lg:col-start-7 lg:col-end-13 lg:px-4 lg:py-4",
            "xl:px-7 xl:py-8",
            "2xl:px-[3.25rem]"
          )}
        >
          <Link
            target="_blank"
            className="hover-default focus-default"
            aria-label={ariaNewTab("Trustpilot")}
            href={Urls.TRUSTPILOT}
          >
            <Image
              src={TrustpilotImage}
              alt="Trustpilot logo with 5 starts"
              className={clsx("h-8 w-auto lg:h-11")}
            />
          </Link>

          <div className="flex-1 relative overflow-hidden">
            {/* Hide longest text to avoid height change. */}
            <div className="invisible">
              <OpinionContent
                opinion={
                  OPINIONS.sort((a, b) => b.text.length - a.text.length)[0]
                }
              />
            </div>
            <AnimatePresence
              initial={false}
              custom={direction}
              mode="popLayout"
            >
              <motion.div
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 0.8, bounce: false }}
                className="flex flex-col flex-1 absolute left-0 right-0 top-0 bottom-0 w-full"
                key={`opinion-${opinionIndex}`}
              >
                <OpinionContent opinion={OPINIONS[opinionIndex]} />
              </motion.div>
            </AnimatePresence>
          </div>

          <div className={clsx("flex gap-4", "lg:gap-[0.625rem]", "xl:gap-3")}>
            <PrevNextButton direction="left" onClick={prevOpinion} />
            <PrevNextButton direction="right" onClick={nextOpinion} />
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default OpinionsSection;
