import clsx from "clsx";
import React, { FunctionComponent } from "react";
import Grid from "../Grid";

type VerticalLinesProps = {
  className?: string;
};

const VerticalLines: FunctionComponent<VerticalLinesProps> = ({
  className,
}) => {
  return (
    <div
      className={clsx(
        className,
        "absolute left-0 right-0 top-0 bottom-0 w-full -z-[1]"
      )}
    >
      <Grid className="w-full h-full">
        <div
          className={clsx(
            "border-x border-x-[#252728] w-full",
            "col-start-2 col-end-4",
            "sm:col-start-3 sm:col-end-5",
            "md:col-start-4 md:col-end-6",
            "lg:col-start-5 lg:col-end-9"
          )}
        />
      </Grid>
    </div>
  );
};

export default VerticalLines;
