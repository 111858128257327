type Opinion = {
  name: string;
  description: string;
  text: string;
};

export const OPINIONS: Opinion[] = [
  {
    name: "Dr Matthew Elliot",
    description: "Kings College London",
    text: "Throughout all our work so far Medical Logistics have been a key partner in this. They have been proactive and responses to the last-minute changes in timings that form part of any study involving surgical samples – and thanks to their work the initial data we have collected shows very promising results.",
  },
  {
    name: "Kirsty Ettrick",
    description: "London Medical Concierge",
    text: "If you're considering working with Medical Logistics, I would explain what it is that you need and how you want to deliver it. The're brilliant at absolutely everything! Working with these guys has completely change our business, enhanced our reputations and increased our membership base. I couldn't work with a better team.",
  },
  {
    name: "Martin",
    description: "Cigna",
    text: "Since the pandemic began it has been difficult to find a test provider that will work with you. We have used Medical Logistics for the past two years, their service level is impeccable, they are able to facilitate tests at short notice, nothing is to much trouble for them. When they say “same day result” they mean same day.",
  },
];
