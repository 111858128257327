import Grid from "@/components/Grid";
import clsx from "clsx";
import React from "react";
import ServicesItem from "./ServicesItem";

const ServicesSection = () => {
  return (
    <section>
      <Grid
        className={clsx(
          "pt-16 pb-10",
          "sm:pt-20",
          "md:pb-10",
          "lg:pt-24 lg:pb-[4.5rem]",
          "2xl:pt-36 2xl:pb-20"
        )}
      >
        <div
          className={clsx(
            "col-span-full flex flex-col gap-8 ",
            "md:col-span-4 md:gap-6",
            "lg:col-span-8 lg:flex-row lg:gap-4",
            "xl:gap-6"
          )}
        >
          <ServicesItem type="medical" />
          <ServicesItem type="couriers" />
        </div>
        <div
          className={clsx(
            "col-span-full flex mt-8 h-fit",
            "md:col-span-4 md:items-center md:h-full md:mt-0",
            "lg:col-span-4 lg:h-auto"
          )}
        >
          <ServicesItem type="in-clinic" />
        </div>
      </Grid>
    </section>
  );
};

export default ServicesSection;
