import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { getSelectorsByUserAgent } from "react-device-detect";

const StarVideo = () => {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const { isIOS } = getSelectorsByUserAgent(navigator.userAgent);
    setIsIOS(isIOS);
  }, []);

  return (
    <video
      className={clsx("aspect-square", !isIOS && "pointer-events-none")}
      loop
      playsInline
      muted
      autoPlay
    >
      <source src={"video/nhs-video.mov"} type="video/quicktime" />
      <source src={"video/nhs-video.webm"} type="video/webm" />
      <track kind="captions" />
    </video>
  );
};

export default StarVideo;
