import Grid from "@/components/Grid";
import LinesContainer from "@/components/LinesContainer";
import clsx from "clsx";
import React, { FunctionComponent, HTMLAttributes } from "react";
import StarVideo from "./StarVideo";
import Image from "next/image";
import ImagePointer from "./image_pointer.webp";
import { motion } from "framer-motion";

type NhsLiProps = Omit<
  HTMLAttributes<HTMLLIElement>,
  "onAnimationStart" | "onDragStart" | "onDragEnd" | "onDrag"
> & { index: number };

const NhsLi: FunctionComponent<NhsLiProps> = ({
  children,
  className,
  index,
  ...props
}) => (
  <motion.li
    initial={{ translateX: 64, opacity: 1 }}
    whileInView={{ translateX: 0, opacity: 1 }}
    viewport={{ once: true }}
    transition={{ bounce: false, duration: 0.5, delay: 0.1 + index * 0.1 }}
    className={clsx(
      className,
      "flex gap-3 body-s text-light-second items-center ",
      "xl:gap-4 xl:body-m"
    )}
    {...props}
  >
    <motion.div
      animate={{ rotate: 360 }}
      initial={{ rotate: 0 }}
      transition={{
        duration: 1.6,
        repeat: Infinity,
        repeatType: "loop",
        ease: "linear",
        delay: index * -0.2,
      }}
    >
      <Image
        src={ImagePointer}
        alt=""
        className={clsx(
          "h-6 w-6",
          "sm:h-[1.625rem] sm:w-[1.625rem]",
          "2xl:h-8 2xl:w-8"
        )}
      />
    </motion.div>
    <span className="flex-1">{children}</span>
  </motion.li>
);

const NhsSection = () => {
  return (
    <section
      className={clsx("pt-12", "sm:py-16", "md:py-20", "xl:py-24", "2xl:py-28")}
    >
      <LinesContainer>
        <div className={clsx("h-px bg-lines-on-dark w-full")} />
      </LinesContainer>

      <div
        className={clsx(
          "my-12",
          "sm:my-16",
          "md:my-20",
          "xl:my-24",
          "2xl:my-28"
        )}
      >
        <Grid>
          <div
            className={clsx(
              "col-span-full mx-6",
              "sm:col-start-3 sm:col-end-7 sm:mx-0 sm:max-w-xs",
              "md:hidden md:max-w-none",
              "lg:col-start-1 lg:col-end-6 lg:flex lg:row-start-1 lg:row-end-3 lg:items-center "
            )}
          >
            <StarVideo />
          </div>
          <div
            className={clsx(
              "flex flex-col gap-3 col-span-full mb-8",
              "sm:-mt-16 sm:max-w-md",
              "md:mt-0 md:col-span-7 md:max-w-[33.125rem]",
              "lg:col-start-6 lg:max-w-none lg:-mr-[9px]",
              "xl:mr-0"
            )}
          >
            <h2
              className={clsx(
                "heading-4 font-bsc",
                "sm:heading-3",
                "xl:heading-2",
                "2xl:heading-1"
              )}
            >
              Mission
              <br className="md:hidden" /> to work for and with the NHS
            </h2>
            <p className={clsx("body-s text-light-second", "xl:body-m")}>
              We believe that we have a responsibility and a duty to work
              alongside the NHS to mitigate some of the strain and relieve some
              of the pressure.{" "}
              <span className="hidden md:inline text-light-main body-s-bold xl:body-m-bold">
                How do we support the NHS?
              </span>
            </p>
          </div>

          <div
            className={clsx(
              "hidden ",
              "md:block md:col-start-1 md:col-end-5",
              "lg:hidden"
            )}
          >
            <StarVideo />
          </div>
          <div
            className={clsx(
              "col-span-full flex flex-col",
              "md:col-start-5 md:col-end-9 md:justify-center",
              "lg:col-start-6 lg:col-end-13",
              "xl:mt-2"
            )}
          >
            <h3 className={clsx("mb-4 body-m-bold", "md:hidden")}>
              How do we support the NHS?
            </h3>
            <ul
              className={clsx(
                "flex flex-col gap-2 overflow-hidden",
                "sm:gap-4",
                "md:gap-3",
                "lg:ml-10",
                "xl:gap-4"
              )}
            >
              <NhsLi index={0}>We took drones to the skies for the NHS!</NhsLi>
              <NhsLi index={1}>
                We work closely with the NHS to transport critical blood and
                medical samples
              </NhsLi>
              <NhsLi index={2}>
                We built Medical Laboratories to provide blood testing and
                health check-ups
              </NhsLi>
            </ul>
          </div>
        </Grid>
      </div>
      <LinesContainer>
        <div className={clsx("h-px bg-lines-on-dark w-full")} />
      </LinesContainer>
    </section>
  );
};

export default NhsSection;
