import {
  RoundedButtonStyles,
  RoundedButtonVariant,
} from "@/styles/RoundedButtonStyles";
import clsx from "clsx";
import Link, { LinkProps } from "next/link";
import React, { FunctionComponent, useId } from "react";

type RoundedLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps & {
    variant?: RoundedButtonVariant;
  };

const RoundedLink: FunctionComponent<RoundedLinkProps> = ({
  style,
  variant = "normal",
  className,
  ...props
}) => {
  const id = useId();
  return (
    <>
      <Link
        className={clsx(className, RoundedButtonStyles(variant))}
        style={{ ...style, clipPath: `url(#${id})` }}
        {...props}
      />
      <svg width="0" height="0">
        <clipPath id={id} clipPathUnits="objectBoundingBox">
          <path d="M0.036,0.212 C0.045,0.086,0.083,-0.003,0.125,0 L0.866,0.05 C0.899,0.053,0.93,0.113,0.944,0.205 L0.99,0.571 C1,0.749,0.988,0.919,0.923,0.925 L0.093,1 C0.034,1,-0.011,0.842,0.002,0.667 L0.036,0.212" />
        </clipPath>
      </svg>
    </>
  );
};

export default RoundedLink;
