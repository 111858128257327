import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import React, { FunctionComponent } from "react";
import ImageDrone from "./image_drone.webp";
import ImageCar from "./image_car.webp";
import Circle from "./circle.webp";
import { SelectedService } from "./SelectedService";

type CardProps = {
  service: SelectedService;
};

const getFirstTitle = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return "Emission Free";
    case "drones":
      return "Use drones for good";
  }
};

const getFirstDescription = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return "Friendlier to the environment. We're proud to have a huge focus on sustainability";
    case "drones":
      return "We completed a first-of-it’s-kind BVLOS drone trial in the UK, in partnership with Skyfarer and the NHS";
  }
};

const getSecondTitle = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return "100% electric";
    case "drones":
      return "Fast delivery";
  }
};

const getSecondDescription = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return "Our goal is to be fully electric within our courier business by the end of 2023";
    case "drones":
      return "A faster, more sustainably friendly solution. Drones save time, money and lives";
  }
};

const getImage = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return ImageCar;
    case "drones":
      return ImageDrone;
  }
};

const getImageAlt = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return "Car image";
    case "drones":
      return "Drone image";
  }
};

const getImageSize = (service: SelectedService) => {
  switch (service) {
    case "cars":
      return { w: 672.5, h: 832.5 };
    case "drones":
      return { w: 732, h: 832 };
  }
};

type CardItemProps = {
  title: string;
  description: string;
  lineDelay?: number;
};

const ServiceAnimation: FunctionComponent<{ service: SelectedService }> = ({
  service,
}) => (
  <div
    className={clsx(
      "flex flex-col gap-4 my-8 ml-8 mr-6",
      "sm:mr-8 sm:ml-6",
      "md:flex-1 md:m-8",
      "lg:gap-6 lg:my-12 lg:mx-14",
      "xl:gap-8 xl:my-14 xl:mx-20"
    )}
  >
    <motion.div
      key={`${service}-inside`}
      initial={{ translateX: "25%", opacity: 0 }}
      exit={{
        translateX: "25%",
        opacity: 0,
        transition: { duration: 0.2, bounce: false },
      }}
      animate={{
        translateX: "0",
        opacity: 1,
        transition: { duration: 0.5, bounce: false },
      }}
    >
      <CardItem
        lineDelay={1}
        title={getFirstTitle(service)}
        description={getFirstDescription(service)}
      />
    </motion.div>
    <motion.div
      key={service}
      initial={{ translateX: "25%", opacity: 0 }}
      exit={{
        translateX: "25%",
        opacity: 0,
        transition: { duration: 0.2, bounce: false },
      }}
      animate={{
        translateX: "0",
        opacity: 1,
        transition: { delay: 0.1, bounce: false, duration: 0.5 },
      }}
      transition={{ bounce: false }}
    >
      <CardItem
        lineDelay={2}
        title={getSecondTitle(service)}
        description={getSecondDescription(service)}
      />
    </motion.div>
  </div>
);

const CardItem: FunctionComponent<CardItemProps> = ({
  title,
  description,
  lineDelay = 0,
}) => {
  return (
    <div className={clsx("flex gap-4", "md:flex-col")}>
      <div className="w-12 h-12 rounded-full relative hidden sm:block">
        <AnimatePresence>
          <motion.div
            initial={{ scaleX: 0 }}
            exit={{ scaleX: 1 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 2, delay: lineDelay }}
            className="hidden absolute h-[1.5px] w-40 bg-lines-on-dark top-0 bottom-0 my-auto right-1/2 origin-right md:block"
          />
        </AnimatePresence>
        <Image loading="eager" src={Circle} alt="" />
      </div>
      <div className="flex flex-col flex-1">
        <h3 className={clsx("title-s-bold", "xl:title-m-bold")}>{title}</h3>
        <p
          className={clsx(
            "text-light-second body-xs",
            "sm:body-s",
            "2xl:body-m"
          )}
        >
          {description}
        </p>
      </div>
    </div>
  );
};

const ServiceImage: FunctionComponent<{
  service: SelectedService;
  hide: boolean;
}> = ({ service, hide }) => (
  <motion.div
    key={service}
    className="w-full h-full absolute left-0 right-0 top-0 bottom-0"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 1 }}
  >
    <Image
      width={getImageSize(service).w}
      height={getImageSize(service).h}
      src={getImage(service)}
      alt={getImageAlt(service)}
      loading="eager"
      className={clsx(
        "h-full w-full object-cover md:object-center",
        service === "cars" && "object-[top_70%_right_0px]",
        service === "drones" && "object-[top_61%_right_0px]",
        service === "cars" && "sm:object-[top_65%_right_0px]",
        service === "drones" && "sm:object-[top_55%_right_0px]"
      )}
    />
  </motion.div>
);

const Card: FunctionComponent<CardProps> = ({ service }) => {
  return (
    <motion.div
      initial={{ opacity: 0, height: "auto" }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: "auto" }}
      layout="size"
      transition={{ bounce: false }}
      className={clsx("flex flex-col", "md:flex-row md:mt-4 origin-top")}
    >
      <div className="flex h-52 relative overflow-hidden sm:h-60 md:flex-1 md:h-full">
        <AnimatePresence>
          {service === "cars" && (
            <ServiceImage key="cars" service="cars" hide={service !== "cars"} />
          )}
          {service === "drones" && (
            <ServiceImage
              key="drones"
              service="drones"
              hide={service !== "drones"}
            />
          )}
        </AnimatePresence>
      </div>
      <motion.div
        className={clsx(
          "md:flex-1 bg-dark-second border border-lines-on-dark relative overflow-hidden"
        )}
      >
        {/* Render invisible card to avoid jumping content because of different text length. */}
        <div className="invisible">
          <ServiceAnimation key="drones" service="drones" />
        </div>
        <div className="absolute left-0 right-0 top-0 bottom-0">
          <AnimatePresence mode="wait">
            {service === "cars" && (
              <ServiceAnimation key="cars" service="cars" />
            )}
            {service === "drones" && (
              <ServiceAnimation key="drones" service="drones" />
            )}
          </AnimatePresence>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Card;
